<template>
  <div>

    <BaseHeader/>

    <BaseSidebar>

      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Persetujuan"
          secondBreadcrumb="Izin"
        />

        <div class="row layout-top-spacing">

          <div class="layout-spacing col-12">
            <PermissionContent/>
          </div>

        </div>

      </div>
    </BaseSidebar>
    <BaseFloatingButtons/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseFloatingButtons from '@/components/base/BaseFloatingButtons'
import PermissionContent from '@/components/approval/permission/PermissionContent'

export default {
  name: 'ApprovalPermission',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseFloatingButtons,
    PermissionContent
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('dashboard', ['permissionWaitingApproval'])
  },
  created() {
    this.getPermissionWaitingApproval({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      token: this.authorization
    })
  },
  methods: {
    ...mapActions('dashboard', ['getPermissionWaitingApproval'])
  }
}
</script>

<style lang="sass" scoped>
</style>