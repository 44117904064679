<template>
  <div>
    <BaseHeader/>

    <BaseSidebar>

      <div class="layout-px-spacing">

        <BaseContentHeader firstBreadcrumb="Karyawan"/>

        <div class="row layout-top-spacing">

          <div class="col-12 layout-spacing">
            <EmployeeTable/>
            <EmployeeMainModal v-if="mainModalState"/>
            <EmployeePersonaliaModal v-else-if="personaliaModalState"/>
          </div>

        </div>
      </div>

    </BaseSidebar>
    
    <div 
      v-if="modal.backdrop" 
      class="modal-backdrop fade show"
    >
    </div>
    <BaseFloatingButtons/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import BaseHeader from "@/components/base/BaseHeader"
import BaseSidebar from "@/components/base/BaseSidebar"
import BaseContentHeader from '@/components/base/BaseContentHeader'
import EmployeeTable from '@/components/employee/EmployeeTable'
import EmployeeMainModal from '@/components/employee/EmployeeMainModal'
import EmployeePersonaliaModal from '@/components/employee/EmployeePersonaliaModal'
import BaseFloatingButtons from '@/components/base/BaseFloatingButtons'

export default {
  name: "Employee",
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    EmployeeTable,
    EmployeeMainModal,
    EmployeePersonaliaModal,
    BaseFloatingButtons
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('styling', ['modal']),
    ...mapState('employee', [ 'mainModalState', 'personaliaModalState']),
    ...mapGetters('employee', ['tableParams'])
  },
  methods: {
    ...mapActions('employee', ['getEmployee']),
  }
}
</script>

<style lang="sass" scoped>
</style>