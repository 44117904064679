<template>
  <div class="row layout-top-spacing">
    <div class="col layout-spacing">
      <div class="widget widget-five custom-widget">
        
        <!-- Report form -->
        <AttendanceSummaryForm formTitle="Laporan Rangkuman"/>
        <!-- Report form -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AttendanceSummaryForm from '@/components/report/attendance-summary/AttendanceSummaryForm'

export default {
  name: "AttendanceSummaryMainContent",
  components: {
    AttendanceSummaryForm,
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('global', ['permissionStatistic'])
  },
  created() {
    this.loadPermissionStatistic(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('global', ['getPermissionStatistic']),
    ...mapMutations('global', ['setPermissionStatistic']),
    loadPermissionStatistic(month) {
      this.setPermissionStatistic({})
      let year = new Date().getFullYear()
      this.getPermissionStatistic({
        month,
        year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-widget
  padding: 0
  border-radius: 6px
</style>