<template>
  <div>

    <BaseHeader/>

    <BaseSidebar>

      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Persetujuan"
          secondBreadcrumb="Kehadiran"
        />

        <div class="d-flex flex-column flex-xl-column">

          <div class="row layout-top-spacing">
            <div class="col layout-spacing">
                <span class="notice-container px-3 d-flex align-items-center text-white">
                  <img 
                    src="@/assets/svg/Notice.svg" 
                    alt="Notice icon" 
                    class="mx-2"
                  >
                  Periksa detail absen karyawan sebelum melakukan persetujuan.
                </span>
            </div>

          </div>

          <div class="row">
            <div class="col layout-spacing pb-0 pb-lg-auto">
              <AttendanceTable/>
              <AttendanceModal/>
            </div>
          </div>

        </div>
      </div>
      
    </BaseSidebar>
    <div 
      v-if="modal.backdrop" 
      class="modal-backdrop fade show"
    ></div>
    <BaseFloatingButtons/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseFloatingButtons from '@/components/base/BaseFloatingButtons'
import AttendanceTable from '@/components/approval/attendance/AttendanceTable'
import AttendanceModal from '@/components/approval/attendance/AttendanceModal'

export default {
  name: 'Attendance',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseFloatingButtons,
    AttendanceTable,
    AttendanceModal
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('approvalAttendance', ['waitingApprovalStatus', 'attendanceValidationStatus']),
    ...mapGetters('auth', ['authorization'])
  },
  created() {
    this.loadWaitingApproval()
    this.loadAttendanceValidation(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('approvalAttendance', ['getWaitingApprovalStatus', 'getAttendanceValidationStatus']),
    ...mapMutations('approvalAttendance', ['setAttendanceValidationStatus']),
    loadWaitingApproval() {
      this.getWaitingApprovalStatus(this.authorization)
    },
    loadAttendanceValidation(month) {
      this.setAttendanceValidationStatus({})
      let year = new Date().getFullYear()
      this.getAttendanceValidationStatus({
        month: month,
        year: year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.notice-container
  padding: 2px
  background-color: #F44336
  border-radius: 5px

.row 
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  flex-wrap: wrap

.row > [class*='col-'] 
  display: flex
  flex-direction: column
</style>